.marketcoverimg {
    width: 100vw;
    height: 80vh;
  }
  
  .marketstitle {
    font-size: 45px;
    color:#000;
    position: absolute;
    top: 40%;
    left: 18%;
    font-weight: 900;
    text-shadow: 2px 2px 4px #ffffff;
  } 

  .cover{
    max-width: 80%;
    margin:auto;
 }

 .levtitle,.ivtitle,.estitle{
  font-size: 34px;
  font-weight: 900;
}
.levtext,.ivtext,.estext{
  font-size: 16px;
  font-weight: 600;
}

.levtext,.ivtext,.estext{
line-height: 1.5;
}


.aboutruler{
 color:#FF3F0F;
 width : 80%;
 margin-left:10%;
}
  

@media only screen and (min-width:626px) and (max-width:927px){
  .marketstitle {
      font-size: 36px;
    }   
}

@media only screen and (max-width:625px){
  .marketstitle {
      font-size: 30px;
    } 
    .markettitlecover{
        margin-bottom: 12px !important;
    }
}

/* Section 1 */

@media only screen and (min-width:509px) and (max-width:826px){

  .levtitle,.ivtitle,.estitle{
      font-size: 30px;
  }
  .levtext,.ivtext,.estext{
      font-size: 16px;
  }
  
  .levtext,.estext{
  max-width: 100%;
  }
  

}

@media only screen and (max-width:508px){
    .marketcoverimg {
        width: 100vw;
        height: 50vh;
      }

        
  .marketstitle {
    font-size: 45px;
    color:#000;
    position: absolute;
    top: 35%;
    left: 18%;
    font-weight: 900;
    text-shadow: 2px 2px 4px #ffffff;
  } 

  .cover{
      max-width: 90%;
  }
  .levtitle,.ivtitle,.estitle{
      font-size: 28px;
  }
  .levtext,.ivtext,.estext{
      font-size: 16px;
  }
  
  .levtext,.estext{
      max-width: 100%;
  }
  
  .levcol,.escol{
      padding:10px !important;
  }

  .ivcol{
      padding:12px !important;
  }

}

.imgundercons{
  margin : auto;
  display: block;
  margin-top: 7%;
}