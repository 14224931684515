.lin{
    list-style-type:none;
    color: #FF3F0F;
    text-align: center; 
    background-color: #ffffff;
    padding : 3px!important;

}

.lin li a{
    color: #000000;
    font-weight: 900;
}
