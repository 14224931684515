.carousal_content_pos{
     padding-top : 30vh;
     padding-left: 70px;

}



.carousal_content_pos_vigor{
    padding-top : 5vh;
    padding-left: 70px;

}

.carousel_content_1{
    font-weight: bold;
    font-size: 65px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}



.carousel_content_2{
    font-size: 25px;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_3{
    font-size: 65px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}

.carousel_content_9{
    font-size: 65px;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}



.carousel_content_4{
    font-weight: bold;
    font-size: 45px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}


.carousel_content_5{
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_6{
    font-weight: bold;
    font-size: 40px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}



.icon_size{
   width: 300px;
}



.vigor_size{
    margin-top:50px ;
    width: 400px;
 }

 .vigor_clip{
    clip-path: polygon(0 0, 47% 0, 73% 0, 50% 100%, 0 100%, 0% 50%);

 }

 .vivid_size{
    margin-top:150px ;
    width: 500px;
 }


 .btn-carousal {
	background: linear-gradient(
		to right,
		#ff823a,
		#f87116
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
    padding: 8px 24px 8px 24px;
	cursor: pointer;
	border-radius: 4px;
    margin-top: 6px;

}

.btn-carousal:hover {
	background: rgb(230, 75, 14);
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	border-radius: 4px;
}
@media only screen and (max-width: 1200px) {


.carousal_content_pos_vigor{
    padding-top : 5vh;
    padding-left: 0px;
    text-align: center;

}
}


/*  
@media only screen and (max-width: 1200px) {

    


    .vigor_size{
        margin-top:35px ;
        width: 200px;
     }
    
    
     .vivid_size{
        margin-top:90px ;
        width: 250px;
     }
    

    .icon_size{
        width: 200px;
     }

    .carousal_content_pos{
    padding-top : 10vh;
     padding-left: 35px;
     
}


.carousal_content_pos_vigor{
   padding-top : 10vh;
   padding-left: 70px;

}


.carousel_content_1{
    font-weight: bold;
    font-size: 35px;
    color: rgb(245, 138, 50);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}

.carousel_content_2{
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}
   

.carousel_content_3{
    font-size: 35px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_4{
    font-weight: bold;
    font-size: 25px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}


.carousel_content_5{
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_6{
    font-weight: bold;
    font-size:20px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}





 .btn-carousal {
	background: linear-gradient(
		to right,
		#ff823a,
		#f87116
	); 
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
    padding: 8px 24px 8px 24px;
	border-radius: 4px;
    margin-top: 6px;

}

.btn-carousal:hover {
	background: rgb(230, 75, 14);
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
    padding: 8px 24px 8px 24px;

	border-radius: 4px;
}

    
}

@media only screen and (max-width: 700px) {

    .vigor_size{
        margin-top:40px ;
        width: 140px;
        margin-left:-15px ;
     }
    
    
     .vivid_size{
        margin-top:60px ;
        margin-left:-20px ;
        width: 180px;
     }

    .icon_size{
        width: 150px;
     }

    .carousal_content_pos{
    padding-top : 5vh;
     padding-left: 15px;
     font-weight: 900;
     font-size: 17px;
     color: white;
}


.carousal_content_pos_vigor{
    padding-top : 10vh;
    padding-left: 10px;
 
 }
   
.carousel_content_1{
    font-weight: bold;
    font-size: 25px;
    color: rgb(245, 138, 50);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}

.carousel_content_2{
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}



 
 
 
 
 .carousel_content_3{
     font-size: 20px;
     color: rgb(255, 255, 255);
     text-transform: capitalize;
     letter-spacing: 1.2px;
     font-weight: bold;
     
     
 }
 
 
 .carousel_content_4{
     font-weight: bold;
     font-size: 15px;
     color: rgb(253, 129, 27);
     text-transform: capitalize;
     letter-spacing: 1.5px;
     padding: 2px;
 }
 
 
 .carousel_content_5{
     font-size: 10px;
     color: rgb(255, 255, 255);
     text-transform: capitalize;
     letter-spacing: 1.2px;
     font-weight: bold;
     
     
 }
 
 
 .carousel_content_6{
     font-weight: bold;
     font-size:20px;
     color: rgb(253, 129, 27);
     text-transform: capitalize;
     letter-spacing: 1.5px;
     padding: 2px;
 }
 
 
 
 
 
  .btn-carousal {
     background: linear-gradient(
         to right,
         #ff823a,
         #f87116
     ); 
     color: rgb(255, 255, 255);
     text-align: center;
     text-decoration: none;
     display: inline-block;
     font-size: 12px;
     cursor: pointer;
     border-radius: 4px;
     margin-top: 6px;
     padding: 6px 20px 6px 20px;
 
 }
 
 .btn-carousal:hover {
	background: rgb(230, 75, 14);
     color: rgb(255, 255, 255);
     text-align: center;
     text-decoration: none;
     display: inline-block;
     font-size: 12px;
     cursor: pointer;
     padding: 6px 20px 6px 20px;
     border-radius: 4px;
 }
    
} */