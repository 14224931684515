/* Title Section */

.contactCoverimg {
	width: 100vw;
	height: 68vh;
}

/* ContactUsSection */

.contactUscover {
	max-width: 90%;
	margin-right: 5%;
	margin-left: 5%;
}

.contactUstitle {
	font-size: 36px;
	font-weight: 900;
}

.contactUscontent {
	font-size: 19px;
	font-weight: 600;
	line-height: 1.5;
	word-spacing: 1.5;
}

/* FormSection */

.formcontainer {
	text-align: center;
	padding: 0% 8% 4%;
	font-weight: bold;
	font-size: medium;
}
form {
	text-align: left;
}
.in-form1 {
	display: inline-block;
	width: 48%;
	margin-right: 4%;
}
.in-form2 {
	display: inline-block;
	width: 48%;
}

input[type="text"],
select,
textarea {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
	background-color: #f5f8fa;
}
.contactrequired {
	color: #ff3f0f;
}
.cmt-inp {
	line-height: 10em;
}
.sub {
	padding-top: 2%;
}

.check {
	padding-top: 2%;
}

.check-box {
	margin-right: 2%;
	margin-left: 1%;
}
.submit {
	border-style: none;
	background-color: #ff3f0f;
	border-radius: 4px;
	padding: 6px 2%;
	color: #fff;
}

.contactformruler {
	color: #ff3f0f;
	width: 80%;
	margin-left: 10%;
}

/*  */
.sending-popup {
	-moz-box-shadow: 0 0 3px #ccc;
	-webkit-box-shadow: 0 0 3px #ccc;
	box-shadow: 0 0 3px #ccc;
	margin: 0 30% 0 30%;
}
.content-popup{
    padding: 5%;
}
/* LocationSection */

.locationcover {
	max-width: 80%;
	margin-left: 13%;
	margin-right: 7%;
}

.locationinnerCover {
	padding-top: 1rem;
}

.Contactlocationtitle {
	text-align: center;
	position: relative;
	right: 5%;
	font-size: 32px;
	font-weight: 900;
	color: #696969;
}

.locationtext1 {
	color: #404040;
	font-size: 28px;
	font-weight: bold;
}

.locationtext2 {
	color: #383838;
	font-size: 22px;
	font-weight: 700;
}

.locationtext3 {
	color: #383838;
	font-size: 18px;
	font-weight: 700;
}

.locationtext4 {
	color: #383838;
	font-size: 22px;
	font-weight: 700;
}

.locationtext5 {
	color: #383838;
	font-size: 18px;
	font-weight: 700;
}
.locationphone {
	padding-right: 0px !important;
	padding-left: 4%;
}

.locationemail {
	padding-left: 0px !important;
}

.locationtext6 {
	color: #383838;
	font-size: 22px;
	font-weight: 700;
}

.locationtext7 {
	color: #383838;
	font-size: 18px;
	font-weight: 700;
}

/* Media Query */

/* ContactUsSection*/

@media only screen and (min-width: 587px) and (max-width: 900px) {
	.contactUstitle {
		font-size: 30px;
	}

	.contactUscontent {
		font-size: 18px;
	}
}

@media only screen and (min-width: 409px) and (max-width: 586px) {
	.contactUstitle {
		font-size: 26px;
		padding-bottom: 0.5rem !important;
	}

	.contactUscontent {
		font-size: 17px;
		padding-top: 0.5rem !important;
	}
}

@media only screen and (max-width: 408px) {
	.contactUscover {
		max-width: 98%;
		margin-right: 1%;
		margin-left: 1%;
		padding: 0.5rem !important;
	}

	.contactUstitle {
		font-size: 22px;
		padding-bottom: 0.5rem !important;
	}

	.contactUscontent {
		font-size: 16px;
		padding-top: 0.5rem !important;
	}
}

/* FormSection */

@media only screen and (max-width: 529px) {
	.formchecktext {
		font-size: 15px;
		margin-bottom: 0 !important;
	}

	.submit {
		font-size: 15px;
	}
}

/* LocationSection */

@media only screen and (min-width: 769px) and (max-width: 1078px) {
	.locationcover {
		max-width: 92%;
		margin-left: 5%;
		margin-right: 2%;
	}

	.Contactlocationtitle {
		font-size: 28px;
		position: relative;
		right: 3%;
	}

	.locationtext1 {
		font-size: 22px;
	}

	.locationtext2,
	.locationtext4,
	.locationtext6 {
		font-size: 20px;
	}

	.locationtext3,
	.locationtext5,
	.locationtext7 {
		font-size: 18px;
	}
}

@media only screen and (min-width: 490px) and (max-width: 768px) {
	.locationcover {
		max-width: 92%;
		margin-left: 5%;
		margin-right: 3%;
	}

	.locationinnerCover {
		padding-top: 0rem !important;
	}

	.Contactlocationtitle {
		font-size: 22px;
		position: relative;
		right: 0%;
	}

	.locationtext1 {
		font-size: 20px;
		padding: 15px 0 !important;
		text-align: center;
	}

	.locationtext2,
	.locationtext4,
	.locationtext6 {
		font-size: 19px;
		text-align: center;
	}

	.locationtext3 {
		font-size: 18px;
		text-align: center;
		padding-bottom: 15px;
		max-width: 60%;
		margin: auto;
	}

	.locationtext5,
	.locationtext7 {
		font-size: 18px;
		text-align: center;
		padding-bottom: 15px;
	}

	.locationphone {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.locationemail {
		padding-left: 15px !important;
	}
}

@media only screen and (max-width: 489px) {

    .contactCoverimg {
        width: 100vw;
        height: 55vh;
    }

    
	.locationcover {
		max-width: 92%;
		margin-left: 5%;
		margin-right: 3%;
	}

	.locationinnerCover {
		padding-top: 0rem !important;
	}

	.Contactlocationtitle {
		font-size: 22px;
		position: relative;
		right: 0%;
	}

	.locationtext1 {
		font-size: 20px;
		padding: 15px 0 !important;
		text-align: center;
	}

	.locationtext2,
	.locationtext4,
	.locationtext6 {
		font-size: 19px;
		text-align: center;
	}

	.locationtext3 {
		max-width: 80%;
		margin: auto;
		font-size: 18px;
		text-align: center;
		padding-bottom: 18px;
	}

	.locationtext5,
	.locationtext7 {
		font-size: 18px;
		text-align: center;
		padding-bottom: 15px;
	}

	.locationphone {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.locationemail {
		padding-left: 15px !important;
	}
}
