/* About page title Section */

.aboutcoverimg {
  width: 100vw;
  height: 70vh;
}

.abouttitle {
  font-size: 45px;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 42%;
  font-weight: 900;
  text-shadow: 2px 2px 4px #ffffff;
} 

.abouttitleText {
  position: absolute;
  text-align: center;
  max-width: 60%;
  top: 42%;
  margin-left: 20%;
  margin-right: 20%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}


/* Section 1 */

.cover{
   max-width: 80%;
   margin:auto;
}

.visionimg,.missionimg{
    box-shadow: -20px -20px 0px 1px rgba(191,218,213,1);
    border-radius: 2%;
}

.visiontitle,.missiontitle{
    font-size: 34px;
    font-weight: 900;
}
.visiontext,.missiontext{
    font-size: 16px;
    font-weight: 600;
}

.visiontext{
max-width: 90%;
line-height: 1.5;
}

.missiontext{
    line-height: 1.5;
}

.aboutruler{
   color:#FF3F0F;
   width : 80%;
   margin-left:10%;
}

/* Section 2 */

.corevaluetitle{
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 0;
}

.corevaluetext{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    word-spacing: 1.5;
    max-width: 42%;
    margin:auto
}

.list{
    list-style-type: circle;
}

.title{
    color: #FF3F0F;
    font-size: 22px;
    font-weight: bold;
}

.Points{
    font-size: 18px;
    font-weight: 600;
}

.corevaluecontain{
    max-width:75%;
    margin:auto;
}

/* Media Query */

/* About page title Section */

@media only screen and (min-width:626px) and (max-width:927px){
    .abouttitle {
        font-size: 36px;
        top: 25%;
      } 
      
      .abouttitleText {
        max-width: 70%;
        top: 36%;
        margin-left: 15%;
        margin-right: 15%;
        font-size: 16px;
      }
      
      
}

@media only screen and (max-width:625px){
    .abouttitle {
        font-size: 30px;
        top: 24%;
        left: 39%;
      } 
      
      .abouttitleText {
        max-width: 80%;
        top: 32%;
        margin-left: 10%;
        margin-right: 10%;
        font-size: 15px;
      } 

      .titlecover{
          margin-bottom: 12px !important;
      }
}

/* Section 1 */

@media only screen and (min-width:509px) and (max-width:826px){

    .visiontitle,.missiontitle{
        font-size: 30px;
    }
    .visiontext,.missiontext{
        font-size: 16px;
    }
    
    .visiontext{
    max-width: 100%;
    }
    

}

@media only screen and (max-width:508px){


    .cover{
        max-width: 90%;
    }
    .visiontitle,.missiontitle{
        font-size: 28px;
    }
    .visiontext,.missiontext{
        font-size: 16px;
    }
    
    .visiontext{
    max-width: 100%;
    }
    
    .visioncol{
        padding:10px !important;
    }

    .missioncol{
        padding:12px !important;
    }

}

/* Section 2 */

@media only screen and (min-width:904px) and (max-width:1125px){

    .corevaluetitle{
        font-size: 28px;
    }
    
    .corevaluetext{
        font-size: 18px;
        max-width: 50%;
    }
    
    .title{
        font-size: 20px;
    }
    
    .Points{
        font-size: 17px;
    }
    
    .corevaluecontain{
        max-width:80%;
    }

}

@media only screen and (min-width:740px) and (max-width:903px){

    .corevaluetitle{
        font-size: 28px;
    }
    
    .corevaluetext{
        font-size: 18px;
        max-width: 60%;
    }
    
    .title{
        font-size: 20px;
    }
    
    .Points{
        font-size: 17px;
    }
    
    .corevaluecontain{
        max-width:90%;
    }
}

@media only screen and (min-width:572px) and (max-width:740px){
 

    .corevaluetitle{
        font-size: 28px;
    }
    
    .corevaluetext{
        font-size: 16px;
        max-width: 60%;
    }
    
    .title{
        font-size: 18px;
    }
    
    .Points{
        font-size: 16px;
    }
    
    .corevaluecontain{
        max-width:95%;
    }

}

@media only screen and (min-width:465px) and (max-width:571px){

    .corevaluetitle{
        font-size: 26px;
    }
    
    .corevaluetext{
        font-size: 15px;
        max-width: 80%;
    }
    
    .title{
        font-size: 17px;
    }
    
    .Points{
        font-size: 15px;
    }
    
    .corevaluecontain{
        max-width:100%;
    }

    .Img{
        margin-top: 2.5rem !important;
        margin-left: 0.5rem !important;
    }
}

@media only screen and (min-width:361px) and (max-width:464px){

    .corevaluetitle{
        font-size: 26px;
    }
    
    .corevaluetext{
        font-size: 15px;
        max-width: 80%;
    }
    
    .title{
        font-size: 17px;
    }
    
    .Points{
        font-size: 15px;
    }
    
    .corevaluecontain{
        max-width:100%;
    }

    .Img{
        margin-top: 3.5rem !important;
        margin-left: 0.5rem !important;
    }

    .aboutimgcol{
        padding: 0 5px;
    }

    .abouttextcol{
        padding-left: 2px !important;
        padding-right:8px !important;
    }

}

@media only screen and (max-width:360px){

    .corevaluetitle{
        font-size: 26px;
    }
    
    .corevaluetext{
        font-size: 15px;
        max-width: 80%;
    }
    
    .title{
        font-size: 17px;
    }
    
    .Points{
        font-size: 15px;
    }
    
    .corevaluecontain{
        max-width:100%;
    }

    .Img{
        margin-top: 3.5rem !important;
        margin-left: 0.5rem !important;
    }

    .aboutimgcol{
        padding: 0;
    }

    .abouttextcol{
        padding-left: 2px !important;
        padding-right:8px !important;
    }

}