
/* @import url('https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@300&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');


/* scroll */
::-webkit-scrollbar {
	width: 3px;
	height: 0px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
    border-radius: 0px;
	background: #ff8c4a;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* common font style  */

* {
	/* font-family: "Gowun Dodum", sans-serif; */
    font-family: 'Quicksand', sans-serif;
    /* font-family: 'Stick No Bills', sans-serif; */
    /* font-family: 'Source Sans Pro', sans-serif; */
    letter-spacing: 1px;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* common wrapper  */

.main-wrapper {
	height: 100vh;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* Edit popup  */

.popup-box_edit {
	position: fixed;
	background: #ffffff71;
	width: 30%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 3;
	overflow-y: auto;
}

.box_edit {
	position: relative;
	width: 100%;
	margin: 0 auto;
	height: auto;
	max-height: 100vh;
	margin-top: calc(100vh - 85vh - 0px);
	background: rgba(255, 255, 255, 0);
	border-radius: 4px;
	padding: 10px;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* footer */

.footer_heading {
	color: hsl(0, 0%, 0%);
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 10px;
	cursor: default;
	letter-spacing: 0.5px;
}

.footer_description {
	color: #443f3f;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.1px;
}

.footer_description:hover {
	color: #d3852b;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.1px;
}

.footer_sub_description {
	color: #000000;
	font-size: 10px;
	font-weight: 600;
	margin-left: 3px;
	letter-spacing: 0.1px;
}

.footer_logo {
	width: 200px;
}

@media only screen and (max-width: 900px) {
	.footer_logo {
		width: 150px;
	}
}

@media only screen and (max-width: 600px) {
	.footer_logo {
		width: 120px;
	}
}


/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* nexigo_button */

.btn-nexi {
	background: linear-gradient(
		to right,
		#fdac7d,
		#f87116
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: rgb(255, 255, 255);
	padding: 7px 14px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	cursor: pointer;
	border-radius: 4px;
}

.btn-nexi:hover {
	background: white;
	border: 0.5px solid rgb(255, 187, 0);
	color: rgb(255, 145, 0);
	padding: 6.5px 13px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	cursor: pointer;
	border-radius: 4px;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* login css */


.logincard {
	margin-top: 1px;
	border-radius: 16px;
}
.em_txt {
	
	font-style: normal;
	font-size: 13px;
	line-height: 13px;
	cursor: pointer;
}
.txtstyle {
	
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 35px;
	margin-bottom: 15px;
}
.txt {
	
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	line-height: 13px;
	color: #0725ce;
	margin-bottom: 16px;
	cursor: pointer;
}
.txt1 {
	font-size: 9px;
	line-height: 12px;
	line-height: 100%;
	margin-bottom: 4px;
}
.txt2 {
	font-style: normal;
	font-weight: normal;
	font-size: 9px;
	line-height: 12px;
	color: #000000;
}
.button4 {
	background-color: white;
	color: black;
	border: 2px solid #e7e7e7;
	border-radius: 4px;
	cursor: pointer;
}
.button4:hover {
	background-color: white;
	color: black;
	border: 2px solid #36ce11;
	border-radius: 4px;
	cursor: pointer;
}
.txt3 {
	font-style: normal;
	font-size: 13px;
}
.button {
	background-color: #0b7eeb; /* Green */
	border: none;
	color: white;
	padding: 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 4px 2px;
	cursor: pointer;
	width: 100px;
	border-radius: 15px;
}
.button:hover {
	background-color: rgb(105, 156, 221);
	color: black;
	border-radius: 15px;
	cursor: pointer;
}
.txt4 {
	
	font-weight: 500;
	font-style: normal;
	font-size: 15px;
	line-height: 13px;
	cursor: pointer;
}
.txt5 {
	
	font-weight: 500;
	font-style: normal;
	font-size: 15px;
	line-height: 13px;
	margin-top: -25px;
}

.usr{
    border-radius: 50%;
    margin-left: 5px;
    width: 24px;
  
  }

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */


/* validation class */

.pass{
    width: 100%;
    padding: 12px;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.pass:focus{
    outline: none !important;
    width: 100%;
    padding: 12px;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}



.pass_text_area   {
    width: 100%;
    padding: 12px;
    line-height: 10em;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.pass_text_area:focus{
    outline: none !important;
    width: 100%;
    line-height: 10em;
    padding: 12px;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}



.warn
   {
    width: 100%;
    padding: 12px;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.warn_text_area
   {
    width: 100%;
    padding: 12px;
	line-height: 10em;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.warn_text_area:focus
   {
    outline: none !important;
    width: 100%;
    padding: 12px;
	line-height: 10em;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.warn:focus{
    outline: none !important;
    width: 100%;
    padding: 12px;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}