/* CarousalSection */

.carousal2verticalimage {
  position: absolute;
  top: 37%;
  right: 19%;
}

.carousal2horizontalimage {
  position: absolute;
  top: 48%;
  right: 1%;
}

.img_fullscreen {
  height: 93vh;
}

@media only screen and (max-width: 1100px) {
  .img_fullscreen {
    height: auto;
  }
} 

/* OurExplorerSection */

.ourexplorercover {
  max-width: 70% !important;
  box-shadow: 0px 0px 22.18px 8px #e5e5e5;
  padding: 3% 8% 4% !important;
  margin-top: 4%;
}

.ourexplorerthreecards {
  display: flex;
  justify-content: space-between;
}

.ourexplorertitle {
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 6%;
}

.ourexplorercard1,
.ourexplorercard2,
.ourexplorercard3 {
  max-width: 33% !important;
}

.marketsImg,
.capabilitiesImg,
.technologyImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
}
.markettitle,
.technologytitle,
.capabilitiestitle {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 10%;
}

.markettext,
.technologytext,
.capabilitiestext {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.explorerlearnmore {
  color: #f15853;
  font-size: 15px;
  font-weight: 900;
  text-align: center;
}


/* VigorSection */

.vigorCover{
  max-width: 86% !important;
  margin-left:8% !important;
  margin-right: 6% !important;
}

.vigortitlesection{
  text-align: center;
  padding-bottom: 3.5rem;
}

.vigortitle{
   color: #FF3F0F;
   font-size:36px;
   font-weight:900;
}

.vigortitledesc{
  font-size: 32px;
  font-weight: 800;
}

.vigortitledesc2{
  font-size: 22px;
  font-weight: bold;
}

.vigorcontentpara{
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
}

.vigunorderedlist{
  list-style-type: none;
  padding-left: 0 !important;
}

li {
    list-style-type: circle;
    position: relative;
    margin: 2px;
    padding: 0.3em;
    background: rgb(255, 255, 255);
    font-family: sans-serif;
    
  }

.viglistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.75;
}

.vigorruler{
  color:#FF3F0F;
  width : 80%;
  margin-left:10%;
}

.vigorlearnmore{
    border-style: none;
    font-size: 14px;
    font-weight: 700;
    background-color: #FF6944;
    border-radius: 4px;
    padding: 2.8% 3.5%;
    color: #fff;
}

/* Vivid Section */

.vividCover{
  max-width: 86% !important;
  margin-left:8% !important;
  margin-right:6% !important;
}

.vividtitlesection{
  text-align: center;
  padding-bottom: 3.5rem;
}

.vividtitle{
   color: #FF3F0F;
   font-size:36px;
   font-weight:900;
}

.vividtitledesc{
  font-size: 32px;
  font-weight: 800;
}

.vividtitledesc2{
  font-size: 22px;
  font-weight: bold;
}

.vividcontentpara{
  font-size: 17px;
  font-weight: 700;
  line-height: 1.5;
}

.vivunorderedlist{
  list-style-type: none;
  padding-left: 0 !important;
}

/* .vivlistitem div div::before{
  font-size:15pt;
  content:"•"; 
} */

.vivlistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.75;
}

.vividruler{
  color:#FF3F0F;
  width : 80%;
  margin-left:10%;
}

.vividlearnmore{
  border-style: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #FF6944;
  border-radius: 4px;
  padding: 2.8% 3.5%;
  color: #fff;
}

.vividImg{
  margin-left:10%;
  margin-top: 15%;
}

/* Industrial Section */

.bmstitle{
  font-size:34px;
  font-weight: 900;
  color: #FF3F0F;
  text-align: center;
}

.bmstitledesc{
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.industrialtitle{
  font-size: 32px;
  font-weight: 900;
  color: #FF3F0F;
}

.industrialtitledesc{
  font-size: 22px;
  font-weight: 700;
}

.industrialcontentpara{
   font-size: 18px;
   font-weight: 800;
   line-height: 1.5;
}

.industriallearnmore{
  border-style: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #FF6944;
  border-radius: 4px;
  padding: 2.8% 3.5%;
  color: #fff;
  position: relative;
  left:27%;
}

.indunorderedlist{
   list-style-type: none;
   padding-left: 0 !important;
}

/* .indlistitem div div::before{
  font-size:15pt;
  content:"•"; 
} */

.indlistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.5;
}

.industrialruler{
  color:#FF3F0F;
  width : 80%;
  margin-left:10%;
}

/* Automotive Section */

.automotivetitle{
  font-size: 32px;
  font-weight: 900;
  color: #FF3F0F;
}

.automotivetitledesc{
  font-size: 22px;
  font-weight: 700;
}

.automotivecontentpara{
   font-size: 18px;
   font-weight: 800;
   line-height: 1.5;
}

.automotivelearnmore{
  border-style: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #FF6944;
  border-radius: 4px;
  padding: 2.8% 3.5%;
  color: #fff;
}

.autunorderedlist{
   list-style-type: none;
   padding-left: 0 !important;
   padding-right: 0 !important;
}

/* .autlistitem div div::before{
  font-size:15pt;
  content:"•"; 
} */

.autlistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.5;
}

.AutomotiveBmsImg{
  position: relative;
  right: 5%;
}


/* Media Query */

/* OurExplorerSection */

@media only screen and (min-width: 790px) and (max-width: 1091px) {
  .ourexplorercover {
    max-width: 90% !important;
  }

  .ourexplorercard1,
  .ourexplorercard2,
  .ourexplorercard3 {
    max-width: 30% !important;
  }
}

@media only screen and (min-width: 520px) and (max-width: 789px) {
  .ourexplorercover {
    max-width: 96% !important;
  }

  .ourexplorercard1,
  .ourexplorercard2,
  .ourexplorercard3 {
    max-width: 35% !important;
  }
}

@media only screen and (max-width: 519px) {
  .ourexplorercover {
    max-width: 80% !important;
  }

  .ourexplorerthreecards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .ourexplorercard1,
  .ourexplorercard2,
  .ourexplorercard3 {
    max-width: 80% !important;
    margin: auto !important;
  }

  .ourexplorertitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 397px){
    .ourexplorercover {
        max-width: 90% !important;
      }
}

/* Vigor Section */

@media only screen and (min-width:569px) and (max-width:988px){

    li {
        list-style-type: circle;
        position: relative;
        margin: 2px;
        padding: 0.2em;
        background: rgb(255, 255, 255);
        font-family: sans-serif;
        
      }

  .vigortitlesection{
    padding-bottom: 0rem;
  }


  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 60% !important;
  }
  
  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 24px;
 }
 
 .vigortitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 17px;
 }

 .viglistitem{
  font-size: 17px;
}

.vigorlearnmore{
  font-size:13px;
}

}

@media only screen and (min-width:435px) and (max-width:568px){

  .vigortitlesection{
    padding-bottom: 0rem;
  }

  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 24px;
 }
 
 .vigortitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 17px;
 }

 .viglistitem{
  font-size: 17px;
}

.vigorlearnmore{
  font-size:12px;
}

}

@media only screen and (min-width:305px) and (max-width:434px){

  .vigortitlesection{
    padding-bottom: 0rem;
  }

  .vigorCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:24px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 18px;
 }
 
 .vigortitledesc2{
   font-size: 17px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 16px;
 }

 .viglistitem{
  font-size: 16px;
}

.vigorlearnmore{
  font-size:12px;
}

}

@media only screen and (max-width:304px) {

  .vigortitlesection{
    padding-bottom: 0rem;
  }

  .vigorCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:22px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 16px;
 }
 
 .vigortitledesc2{
   font-size: 15px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 14px;
 }

 .viglistitem{
  font-size: 14px;
}

.vigorlearnmore{
  font-size:11px;
}

}

/* Vivid Section */

@media only screen and (min-width:569px) and (max-width:988px){

  .vividtitlesection{
    padding-bottom: 0rem;
  }


  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 60% !important;
  }
  
  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 24px;
 }
 
 .vividtitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 17px;
 }

 .vivlistitem{
  font-size: 17px;
}

.vividlearnmore{
  font-size:13px;
}

}

@media only screen and (min-width:435px) and (max-width:568px){

  .vividtitlesection{
    padding-bottom: 0rem;
  }

  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 24px;
 }
 
 .vividtitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 17px;
 }

 .vivlistitem{
  font-size: 17px;
}

.vividlearnmore{
  font-size:12px;
}

}

@media only screen and (min-width:305px) and (max-width:434px){

  .vividtitlesection{
    padding-bottom: 0rem;
  }

  .vividCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:24px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 18px;
 }
 
 .vividtitledesc2{
   font-size: 17px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 16px;
 }

 .vivlistitem{
  font-size: 16px;
}

.vividlearnmore{
  font-size:12px;
}

}

@media only screen and (max-width:304px) {

  .vividtitlesection{
    padding-bottom: 0rem;
  }

  .vividCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:22px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 16px;
 }
 
 .vividtitledesc2{
   font-size: 15px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 14px;
 }

 .vivlistitem{
  font-size: 14px;
}

.vividlearnmore{
  font-size:11px;
}

}

/* Industrial Section */

@media only screen and (min-width:987px) and (max-width:1100px){

  .industrialCover{
    max-width:90% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:32px;
  }
  
  .bmstitledesc{
    font-size: 20px;
  }
  
  .industrialtitle{
    font-size: 26px;
  }
  
  .industrialtitledesc{
    font-size: 20px;
  }
  
  .industrialcontentpara{
     font-size: 17px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .indlistitem{
    font-size: 17px;
  }
  
}

@media only screen and (min-width:507px) and (max-width:986px){

  .industrialCover{
    max-width:90% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:30px;
  }
  
  .bmstitledesc{
    font-size: 20px;
  }
  
  .industrialtitle{
    font-size: 24px;
  }
  
  .industrialtitledesc{
    font-size: 20px;
  }
  
  .industrialcontentpara{
     font-size: 17px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    left:17%;
  }
  
  .indlistitem{
    font-size: 17px;
  }

  .IndustrialBmsImg{
    display: block !important;
    width: 60% !important;
  }

  
}

@media only screen and (min-width:431px) and (max-width:506px){


  .industrialCover{
    max-width:90% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:26px;
  }
  
  .bmstitledesc{
    font-size: 18px;
  }

  
  .industrialtitle{
    font-size: 20px;
  }
  
  .industrialtitledesc{
    font-size: 18px;
  }
  
  .industrialcontentpara{
     font-size: 16px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    position: relative;
    left:35%;
  }
  
  .indlistitem{
    font-size: 16px;
  }

  .IndustrialBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  
}

@media only screen and (min-width:301px) and (max-width:430px){
  .industrialCover{
    max-width:96% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:22px;
  }
  
  .bmstitledesc{
    font-size: 17px;
  }

  
  .industrialtitle{
    font-size: 18px;
  }
  
  .industrialtitledesc{
    font-size: 16px;
  }
  
  .industrialcontentpara{
     font-size: 15px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    position: relative;
    left:35%;
  }
  
  .indlistitem{
    font-size: 15px;
  }

  .IndustrialBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

}

@media only screen and (max-width:300px){
  .industrialCover{
    max-width:96% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:20px;
  }
  
  .bmstitledesc{
    font-size: 16px;
  }

  
  .industrialtitle{
    font-size: 17px;
  }
  
  .industrialtitledesc{
    font-size: 15px;
  }
  
  .industrialcontentpara{
     font-size: 14px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    position: relative;
    left:28%;
  }
  
  .indlistitem{
    font-size: 14px;
  }

  .IndustrialBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }
}

/* Automotive Section */

@media only screen and (min-width:987px) and (max-width:1100px){

  .automotiveCover{
    max-width:90% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 26px;
  }
  
  .automotivetitledesc{
    font-size: 20px;
  }
  
  .automotivecontentpara{
     font-size: 17px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 17px;
  }
  
}

@media only screen and (min-width:507px) and (max-width:986px){

  .automotiveCover{
    max-width:90% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 24px;
  }
  
  .automotivetitledesc{
    font-size: 20px;
  }
  
  .automotivecontentpara{
     font-size: 17px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 17px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    width: 60% !important;
  }
  
}

@media only screen and (min-width:431px) and (max-width:506px){

  .automotiveCover{
    max-width:90% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 22px;
  }
  
  .automotivetitledesc{
    font-size: 18px;
  }
  
  .automotivecontentpara{
     font-size: 16px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 16px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }
  
}

@media only screen and (min-width:301px) and (max-width:430px){
  .automotiveCover{
    max-width:96% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 20px;
  }
  
  .automotivetitledesc{
    font-size: 17px;
  }
  
  .automotivecontentpara{
     font-size: 15px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 15px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

}

@media only screen and (max-width:300px){
  .automotiveCover{
    max-width:96% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 17px;
  }
  
  .automotivetitledesc{
    font-size: 15px;
  }
  
  .automotivecontentpara{
     font-size: 14px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 14px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

}