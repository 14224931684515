.Navbarbigscreen{
    padding : 5px 5px 5px 25px;
}

.navstyle{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(54, 54, 54);;
    text-decoration: none!important;
  }

  


  .navstyle:hover{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(247, 151, 8);;
    text-decoration:none;
    text-decoration-color: rgb(228, 93, 3);
    transform: 'right 1s';
  }

  .active_nav{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(247, 84, 8);;
    text-decoration: none!important;   
  }

  
  .active_nav:hover{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(247, 84, 8);;
    text-decoration: none!important;
   
   
  }



  .nav_button{
    display: block;
    padding: 0.9rem 0rem 0.5rem 0rem;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
    color: hsl(32, 82%, 53%);;
    text-decoration: none!important;
  }


  .navuser{
    display: block;
    padding: 0.8rem 0rem 0rem 0rem;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none!important;
  }

  .user{
    border-radius: 50%;
    margin-top:0px;
    margin-left: 5px;
    width: 28px;
  }

 

  .navlogo{
    width: 130px;
    display: block;
    padding: 0.5rem 0rem 0.5rem 0rem;
    text-transform: uppercase;
    text-decoration: none!important;
  }

 
@media only screen and (max-width: 1100px) {

    .navuser{
        display: block;
        padding: 0.2rem 0rem 0.1rem 0rem;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none!important;
      }

      
    .navlogo{
        width: 100px;
        display: block;
        padding: 0rem 0rem 0rem 0rem;
        text-transform: uppercase;
        text-decoration: none!important;
      }

    

      .ham{
        display: block;
        padding: 0.3rem 0rem 0.2rem 0rem;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 800;
        text-decoration: none!important;
      }
}




  