.model {
    font-family: 'Gowun Dodum', sans-serif;
}

.modal-confirm {
    color: #636363;
    width: 325px;
    margin: auto;
    font-size: 14px;
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    margin: auto;
}

.modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
    margin-top: 15vh;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}

.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}

.modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #FF3F0F;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #FF3F0F;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: linear-gradient(90deg, #f87116, #FF3F0F);
    outline: none;
    box-shadow: none;
}


.modal-title {
    padding: 2% 0 2% 0;
    font-size: xx-large !important;
}

.modal-body {
    padding: 5% 0 2% 0;
}

.text {
    padding: 1% 0 1% 0;
    font-size: medium;
    text-align: center;
    line-height: 1.5;
    word-spacing: 0.2rem;
}

@media only screen and (max-width: 325px) {
    .modal-confirm .modal-header {
        margin-top: 12vh;
    }

    .modal-confirm .icon-box i {
        font-size: 48px;
        top: 8px;
    }

    .modal-confirm .icon-box {
        width: 90px;
        height: 90px;
    }

    .modal-confirm {
        width: 90% !important;
    }

    .modal-title {
        font-size: x-large !important;
    }

    .text {
        font-size: 15px;
    }

}