@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/* @import url('https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@300&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap'); */


/* scroll */
::-webkit-scrollbar {
	width: 3px;
	height: 0px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
    border-radius: 0px;
	background: #ff8c4a;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* common font style  */

* {
	/* font-family: "Gowun Dodum", sans-serif; */
    font-family: 'Quicksand', sans-serif;
    /* font-family: 'Stick No Bills', sans-serif; */
    /* font-family: 'Source Sans Pro', sans-serif; */
    letter-spacing: 1px;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* common wrapper  */

.main-wrapper {
	height: 100vh;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* Edit popup  */

.popup-box_edit {
	position: fixed;
	background: #ffffff71;
	width: 30%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 3;
	overflow-y: auto;
}

.box_edit {
	position: relative;
	width: 100%;
	margin: 0 auto;
	height: auto;
	max-height: 100vh;
	margin-top: calc(100vh - 85vh - 0px);
	background: rgba(255, 255, 255, 0);
	border-radius: 4px;
	padding: 10px;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* footer */

.footer_heading {
	color: hsl(0, 0%, 0%);
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 10px;
	cursor: default;
	letter-spacing: 0.5px;
}

.footer_description {
	color: #443f3f;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.1px;
}

.footer_description:hover {
	color: #d3852b;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.1px;
}

.footer_sub_description {
	color: #000000;
	font-size: 10px;
	font-weight: 600;
	margin-left: 3px;
	letter-spacing: 0.1px;
}

.footer_logo {
	width: 200px;
}

@media only screen and (max-width: 900px) {
	.footer_logo {
		width: 150px;
	}
}

@media only screen and (max-width: 600px) {
	.footer_logo {
		width: 120px;
	}
}


/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* nexigo_button */

.btn-nexi {
	background: linear-gradient(
		to right,
		#fdac7d,
		#f87116
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: rgb(255, 255, 255);
	padding: 7px 14px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	cursor: pointer;
	border-radius: 4px;
}

.btn-nexi:hover {
	background: white;
	border: 0.5px solid rgb(255, 187, 0);
	color: rgb(255, 145, 0);
	padding: 6.5px 13px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	cursor: pointer;
	border-radius: 4px;
}

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */

/* login css */


.logincard {
	margin-top: 1px;
	border-radius: 16px;
}
.em_txt {
	
	font-style: normal;
	font-size: 13px;
	line-height: 13px;
	cursor: pointer;
}
.txtstyle {
	
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 35px;
	margin-bottom: 15px;
}
.txt {
	
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	line-height: 13px;
	color: #0725ce;
	margin-bottom: 16px;
	cursor: pointer;
}
.txt1 {
	font-size: 9px;
	line-height: 12px;
	line-height: 100%;
	margin-bottom: 4px;
}
.txt2 {
	font-style: normal;
	font-weight: normal;
	font-size: 9px;
	line-height: 12px;
	color: #000000;
}
.button4 {
	background-color: white;
	color: black;
	border: 2px solid #e7e7e7;
	border-radius: 4px;
	cursor: pointer;
}
.button4:hover {
	background-color: white;
	color: black;
	border: 2px solid #36ce11;
	border-radius: 4px;
	cursor: pointer;
}
.txt3 {
	font-style: normal;
	font-size: 13px;
}
.button {
	background-color: #0b7eeb; /* Green */
	border: none;
	color: white;
	padding: 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 4px 2px;
	cursor: pointer;
	width: 100px;
	border-radius: 15px;
}
.button:hover {
	background-color: rgb(105, 156, 221);
	color: black;
	border-radius: 15px;
	cursor: pointer;
}
.txt4 {
	
	font-weight: 500;
	font-style: normal;
	font-size: 15px;
	line-height: 13px;
	cursor: pointer;
}
.txt5 {
	
	font-weight: 500;
	font-style: normal;
	font-size: 15px;
	line-height: 13px;
	margin-top: -25px;
}

.usr{
    border-radius: 50%;
    margin-left: 5px;
    width: 24px;
  
  }

/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */


/* validation class */

.pass{
    width: 100%;
    padding: 12px;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.pass:focus{
    outline: none !important;
    width: 100%;
    padding: 12px;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}



.pass_text_area   {
    width: 100%;
    padding: 12px;
    line-height: 10em;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.pass_text_area:focus{
    outline: none !important;
    width: 100%;
    line-height: 10em;
    padding: 12px;
    border: 2px solid #76dd56!important;
    border-color: #9eedbc!important;
    box-shadow: 0 0 5px #b0ed9e;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}



.warn
   {
    width: 100%;
    padding: 12px;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.warn_text_area
   {
    width: 100%;
    padding: 12px;
	line-height: 10em;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.warn_text_area:focus
   {
    outline: none !important;
    width: 100%;
    padding: 12px;
	line-height: 10em;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}


.warn:focus{
    outline: none !important;
    width: 100%;
    padding: 12px;
    border: 2px solid #dd5656!important;
    border-color: #ed9e9e!important;
    box-shadow: 0 0 5px #f16969;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #ffffff!important ;
}
/* Title Section */

.contactCoverimg {
	width: 100vw;
	height: 68vh;
}

/* ContactUsSection */

.contactUscover {
	max-width: 90%;
	margin-right: 5%;
	margin-left: 5%;
}

.contactUstitle {
	font-size: 36px;
	font-weight: 900;
}

.contactUscontent {
	font-size: 19px;
	font-weight: 600;
	line-height: 1.5;
	word-spacing: 1.5;
}

/* FormSection */

.formcontainer {
	text-align: center;
	padding: 0% 8% 4%;
	font-weight: bold;
	font-size: medium;
}
form {
	text-align: left;
}
.in-form1 {
	display: inline-block;
	width: 48%;
	margin-right: 4%;
}
.in-form2 {
	display: inline-block;
	width: 48%;
}

input[type="text"],
select,
textarea {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
	background-color: #f5f8fa;
}
.contactrequired {
	color: #ff3f0f;
}
.cmt-inp {
	line-height: 10em;
}
.sub {
	padding-top: 2%;
}

.check {
	padding-top: 2%;
}

.check-box {
	margin-right: 2%;
	margin-left: 1%;
}
.submit {
	border-style: none;
	background-color: #ff3f0f;
	border-radius: 4px;
	padding: 6px 2%;
	color: #fff;
}

.contactformruler {
	color: #ff3f0f;
	width: 80%;
	margin-left: 10%;
}

/*  */
.sending-popup {
	box-shadow: 0 0 3px #ccc;
	margin: 0 30% 0 30%;
}
.content-popup{
    padding: 5%;
}
/* LocationSection */

.locationcover {
	max-width: 80%;
	margin-left: 13%;
	margin-right: 7%;
}

.locationinnerCover {
	padding-top: 1rem;
}

.Contactlocationtitle {
	text-align: center;
	position: relative;
	right: 5%;
	font-size: 32px;
	font-weight: 900;
	color: #696969;
}

.locationtext1 {
	color: #404040;
	font-size: 28px;
	font-weight: bold;
}

.locationtext2 {
	color: #383838;
	font-size: 22px;
	font-weight: 700;
}

.locationtext3 {
	color: #383838;
	font-size: 18px;
	font-weight: 700;
}

.locationtext4 {
	color: #383838;
	font-size: 22px;
	font-weight: 700;
}

.locationtext5 {
	color: #383838;
	font-size: 18px;
	font-weight: 700;
}
.locationphone {
	padding-right: 0px !important;
	padding-left: 4%;
}

.locationemail {
	padding-left: 0px !important;
}

.locationtext6 {
	color: #383838;
	font-size: 22px;
	font-weight: 700;
}

.locationtext7 {
	color: #383838;
	font-size: 18px;
	font-weight: 700;
}

/* Media Query */

/* ContactUsSection*/

@media only screen and (min-width: 587px) and (max-width: 900px) {
	.contactUstitle {
		font-size: 30px;
	}

	.contactUscontent {
		font-size: 18px;
	}
}

@media only screen and (min-width: 409px) and (max-width: 586px) {
	.contactUstitle {
		font-size: 26px;
		padding-bottom: 0.5rem !important;
	}

	.contactUscontent {
		font-size: 17px;
		padding-top: 0.5rem !important;
	}
}

@media only screen and (max-width: 408px) {
	.contactUscover {
		max-width: 98%;
		margin-right: 1%;
		margin-left: 1%;
		padding: 0.5rem !important;
	}

	.contactUstitle {
		font-size: 22px;
		padding-bottom: 0.5rem !important;
	}

	.contactUscontent {
		font-size: 16px;
		padding-top: 0.5rem !important;
	}
}

/* FormSection */

@media only screen and (max-width: 529px) {
	.formchecktext {
		font-size: 15px;
		margin-bottom: 0 !important;
	}

	.submit {
		font-size: 15px;
	}
}

/* LocationSection */

@media only screen and (min-width: 769px) and (max-width: 1078px) {
	.locationcover {
		max-width: 92%;
		margin-left: 5%;
		margin-right: 2%;
	}

	.Contactlocationtitle {
		font-size: 28px;
		position: relative;
		right: 3%;
	}

	.locationtext1 {
		font-size: 22px;
	}

	.locationtext2,
	.locationtext4,
	.locationtext6 {
		font-size: 20px;
	}

	.locationtext3,
	.locationtext5,
	.locationtext7 {
		font-size: 18px;
	}
}

@media only screen and (min-width: 490px) and (max-width: 768px) {
	.locationcover {
		max-width: 92%;
		margin-left: 5%;
		margin-right: 3%;
	}

	.locationinnerCover {
		padding-top: 0rem !important;
	}

	.Contactlocationtitle {
		font-size: 22px;
		position: relative;
		right: 0%;
	}

	.locationtext1 {
		font-size: 20px;
		padding: 15px 0 !important;
		text-align: center;
	}

	.locationtext2,
	.locationtext4,
	.locationtext6 {
		font-size: 19px;
		text-align: center;
	}

	.locationtext3 {
		font-size: 18px;
		text-align: center;
		padding-bottom: 15px;
		max-width: 60%;
		margin: auto;
	}

	.locationtext5,
	.locationtext7 {
		font-size: 18px;
		text-align: center;
		padding-bottom: 15px;
	}

	.locationphone {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.locationemail {
		padding-left: 15px !important;
	}
}

@media only screen and (max-width: 489px) {

    .contactCoverimg {
        width: 100vw;
        height: 55vh;
    }

    
	.locationcover {
		max-width: 92%;
		margin-left: 5%;
		margin-right: 3%;
	}

	.locationinnerCover {
		padding-top: 0rem !important;
	}

	.Contactlocationtitle {
		font-size: 22px;
		position: relative;
		right: 0%;
	}

	.locationtext1 {
		font-size: 20px;
		padding: 15px 0 !important;
		text-align: center;
	}

	.locationtext2,
	.locationtext4,
	.locationtext6 {
		font-size: 19px;
		text-align: center;
	}

	.locationtext3 {
		max-width: 80%;
		margin: auto;
		font-size: 18px;
		text-align: center;
		padding-bottom: 18px;
	}

	.locationtext5,
	.locationtext7 {
		font-size: 18px;
		text-align: center;
		padding-bottom: 15px;
	}

	.locationphone {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.locationemail {
		padding-left: 15px !important;
	}
}

.carousal_content_pos{
     padding-top : 30vh;
     padding-left: 70px;

}



.carousal_content_pos_vigor{
    padding-top : 5vh;
    padding-left: 70px;

}

.carousel_content_1{
    font-weight: bold;
    font-size: 65px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}



.carousel_content_2{
    font-size: 25px;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_3{
    font-size: 65px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}

.carousel_content_9{
    font-size: 65px;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}



.carousel_content_4{
    font-weight: bold;
    font-size: 45px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}


.carousel_content_5{
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_6{
    font-weight: bold;
    font-size: 40px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}



.icon_size{
   width: 300px;
}



.vigor_size{
    margin-top:50px ;
    width: 400px;
 }

 .vigor_clip{
    -webkit-clip-path: polygon(0 0, 47% 0, 73% 0, 50% 100%, 0 100%, 0% 50%);
            clip-path: polygon(0 0, 47% 0, 73% 0, 50% 100%, 0 100%, 0% 50%);

 }

 .vivid_size{
    margin-top:150px ;
    width: 500px;
 }


 .btn-carousal {
	background: linear-gradient(
		to right,
		#ff823a,
		#f87116
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
    padding: 8px 24px 8px 24px;
	cursor: pointer;
	border-radius: 4px;
    margin-top: 6px;

}

.btn-carousal:hover {
	background: rgb(230, 75, 14);
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	border-radius: 4px;
}
@media only screen and (max-width: 1200px) {


.carousal_content_pos_vigor{
    padding-top : 5vh;
    padding-left: 0px;
    text-align: center;

}
}


/*  
@media only screen and (max-width: 1200px) {

    


    .vigor_size{
        margin-top:35px ;
        width: 200px;
     }
    
    
     .vivid_size{
        margin-top:90px ;
        width: 250px;
     }
    

    .icon_size{
        width: 200px;
     }

    .carousal_content_pos{
    padding-top : 10vh;
     padding-left: 35px;
     
}


.carousal_content_pos_vigor{
   padding-top : 10vh;
   padding-left: 70px;

}


.carousel_content_1{
    font-weight: bold;
    font-size: 35px;
    color: rgb(245, 138, 50);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}

.carousel_content_2{
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}
   

.carousel_content_3{
    font-size: 35px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_4{
    font-weight: bold;
    font-size: 25px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}


.carousel_content_5{
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}


.carousel_content_6{
    font-weight: bold;
    font-size:20px;
    color: rgb(253, 129, 27);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}





 .btn-carousal {
	background: linear-gradient(
		to right,
		#ff823a,
		#f87116
	); 
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
    padding: 8px 24px 8px 24px;
	border-radius: 4px;
    margin-top: 6px;

}

.btn-carousal:hover {
	background: rgb(230, 75, 14);
	color: rgb(255, 255, 255);
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
    padding: 8px 24px 8px 24px;

	border-radius: 4px;
}

    
}

@media only screen and (max-width: 700px) {

    .vigor_size{
        margin-top:40px ;
        width: 140px;
        margin-left:-15px ;
     }
    
    
     .vivid_size{
        margin-top:60px ;
        margin-left:-20px ;
        width: 180px;
     }

    .icon_size{
        width: 150px;
     }

    .carousal_content_pos{
    padding-top : 5vh;
     padding-left: 15px;
     font-weight: 900;
     font-size: 17px;
     color: white;
}


.carousal_content_pos_vigor{
    padding-top : 10vh;
    padding-left: 10px;
 
 }
   
.carousel_content_1{
    font-weight: bold;
    font-size: 25px;
    color: rgb(245, 138, 50);
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding: 2px;
}

.carousel_content_2{
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    letter-spacing: 1.2px;
    font-weight: bold;
    
    
}



 
 
 
 
 .carousel_content_3{
     font-size: 20px;
     color: rgb(255, 255, 255);
     text-transform: capitalize;
     letter-spacing: 1.2px;
     font-weight: bold;
     
     
 }
 
 
 .carousel_content_4{
     font-weight: bold;
     font-size: 15px;
     color: rgb(253, 129, 27);
     text-transform: capitalize;
     letter-spacing: 1.5px;
     padding: 2px;
 }
 
 
 .carousel_content_5{
     font-size: 10px;
     color: rgb(255, 255, 255);
     text-transform: capitalize;
     letter-spacing: 1.2px;
     font-weight: bold;
     
     
 }
 
 
 .carousel_content_6{
     font-weight: bold;
     font-size:20px;
     color: rgb(253, 129, 27);
     text-transform: capitalize;
     letter-spacing: 1.5px;
     padding: 2px;
 }
 
 
 
 
 
  .btn-carousal {
     background: linear-gradient(
         to right,
         #ff823a,
         #f87116
     ); 
     color: rgb(255, 255, 255);
     text-align: center;
     text-decoration: none;
     display: inline-block;
     font-size: 12px;
     cursor: pointer;
     border-radius: 4px;
     margin-top: 6px;
     padding: 6px 20px 6px 20px;
 
 }
 
 .btn-carousal:hover {
	background: rgb(230, 75, 14);
     color: rgb(255, 255, 255);
     text-align: center;
     text-decoration: none;
     display: inline-block;
     font-size: 12px;
     cursor: pointer;
     padding: 6px 20px 6px 20px;
     border-radius: 4px;
 }
    
} */
/* CarousalSection */

.carousal2verticalimage {
  position: absolute;
  top: 37%;
  right: 19%;
}

.carousal2horizontalimage {
  position: absolute;
  top: 48%;
  right: 1%;
}

.img_fullscreen {
  height: 93vh;
}

@media only screen and (max-width: 1100px) {
  .img_fullscreen {
    height: auto;
  }
} 

/* OurExplorerSection */

.ourexplorercover {
  max-width: 70% !important;
  box-shadow: 0px 0px 22.18px 8px #e5e5e5;
  padding: 3% 8% 4% !important;
  margin-top: 4%;
}

.ourexplorerthreecards {
  display: flex;
  justify-content: space-between;
}

.ourexplorertitle {
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 6%;
}

.ourexplorercard1,
.ourexplorercard2,
.ourexplorercard3 {
  max-width: 33% !important;
}

.marketsImg,
.capabilitiesImg,
.technologyImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
}
.markettitle,
.technologytitle,
.capabilitiestitle {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 10%;
}

.markettext,
.technologytext,
.capabilitiestext {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.explorerlearnmore {
  color: #f15853;
  font-size: 15px;
  font-weight: 900;
  text-align: center;
}


/* VigorSection */

.vigorCover{
  max-width: 86% !important;
  margin-left:8% !important;
  margin-right: 6% !important;
}

.vigortitlesection{
  text-align: center;
  padding-bottom: 3.5rem;
}

.vigortitle{
   color: #FF3F0F;
   font-size:36px;
   font-weight:900;
}

.vigortitledesc{
  font-size: 32px;
  font-weight: 800;
}

.vigortitledesc2{
  font-size: 22px;
  font-weight: bold;
}

.vigorcontentpara{
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
}

.vigunorderedlist{
  list-style-type: none;
  padding-left: 0 !important;
}

li {
    list-style-type: circle;
    position: relative;
    margin: 2px;
    padding: 0.3em;
    background: rgb(255, 255, 255);
    font-family: sans-serif;
    
  }

.viglistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.75;
}

.vigorruler{
  color:#FF3F0F;
  width : 80%;
  margin-left:10%;
}

.vigorlearnmore{
    border-style: none;
    font-size: 14px;
    font-weight: 700;
    background-color: #FF6944;
    border-radius: 4px;
    padding: 2.8% 3.5%;
    color: #fff;
}

/* Vivid Section */

.vividCover{
  max-width: 86% !important;
  margin-left:8% !important;
  margin-right:6% !important;
}

.vividtitlesection{
  text-align: center;
  padding-bottom: 3.5rem;
}

.vividtitle{
   color: #FF3F0F;
   font-size:36px;
   font-weight:900;
}

.vividtitledesc{
  font-size: 32px;
  font-weight: 800;
}

.vividtitledesc2{
  font-size: 22px;
  font-weight: bold;
}

.vividcontentpara{
  font-size: 17px;
  font-weight: 700;
  line-height: 1.5;
}

.vivunorderedlist{
  list-style-type: none;
  padding-left: 0 !important;
}

/* .vivlistitem div div::before{
  font-size:15pt;
  content:"•"; 
} */

.vivlistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.75;
}

.vividruler{
  color:#FF3F0F;
  width : 80%;
  margin-left:10%;
}

.vividlearnmore{
  border-style: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #FF6944;
  border-radius: 4px;
  padding: 2.8% 3.5%;
  color: #fff;
}

.vividImg{
  margin-left:10%;
  margin-top: 15%;
}

/* Industrial Section */

.bmstitle{
  font-size:34px;
  font-weight: 900;
  color: #FF3F0F;
  text-align: center;
}

.bmstitledesc{
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.industrialtitle{
  font-size: 32px;
  font-weight: 900;
  color: #FF3F0F;
}

.industrialtitledesc{
  font-size: 22px;
  font-weight: 700;
}

.industrialcontentpara{
   font-size: 18px;
   font-weight: 800;
   line-height: 1.5;
}

.industriallearnmore{
  border-style: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #FF6944;
  border-radius: 4px;
  padding: 2.8% 3.5%;
  color: #fff;
  position: relative;
  left:27%;
}

.indunorderedlist{
   list-style-type: none;
   padding-left: 0 !important;
}

/* .indlistitem div div::before{
  font-size:15pt;
  content:"•"; 
} */

.indlistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.5;
}

.industrialruler{
  color:#FF3F0F;
  width : 80%;
  margin-left:10%;
}

/* Automotive Section */

.automotivetitle{
  font-size: 32px;
  font-weight: 900;
  color: #FF3F0F;
}

.automotivetitledesc{
  font-size: 22px;
  font-weight: 700;
}

.automotivecontentpara{
   font-size: 18px;
   font-weight: 800;
   line-height: 1.5;
}

.automotivelearnmore{
  border-style: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #FF6944;
  border-radius: 4px;
  padding: 2.8% 3.5%;
  color: #fff;
}

.autunorderedlist{
   list-style-type: none;
   padding-left: 0 !important;
   padding-right: 0 !important;
}

/* .autlistitem div div::before{
  font-size:15pt;
  content:"•"; 
} */

.autlistitem{
  font-size: 18px;
  font-weight: 700;
  word-spacing: 1.5;
  line-height: 1.5;
}

.AutomotiveBmsImg{
  position: relative;
  right: 5%;
}


/* Media Query */

/* OurExplorerSection */

@media only screen and (min-width: 790px) and (max-width: 1091px) {
  .ourexplorercover {
    max-width: 90% !important;
  }

  .ourexplorercard1,
  .ourexplorercard2,
  .ourexplorercard3 {
    max-width: 30% !important;
  }
}

@media only screen and (min-width: 520px) and (max-width: 789px) {
  .ourexplorercover {
    max-width: 96% !important;
  }

  .ourexplorercard1,
  .ourexplorercard2,
  .ourexplorercard3 {
    max-width: 35% !important;
  }
}

@media only screen and (max-width: 519px) {
  .ourexplorercover {
    max-width: 80% !important;
  }

  .ourexplorerthreecards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .ourexplorercard1,
  .ourexplorercard2,
  .ourexplorercard3 {
    max-width: 80% !important;
    margin: auto !important;
  }

  .ourexplorertitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 397px){
    .ourexplorercover {
        max-width: 90% !important;
      }
}

/* Vigor Section */

@media only screen and (min-width:569px) and (max-width:988px){

    li {
        list-style-type: circle;
        position: relative;
        margin: 2px;
        padding: 0.2em;
        background: rgb(255, 255, 255);
        font-family: sans-serif;
        
      }

  .vigortitlesection{
    padding-bottom: 0rem;
  }


  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 60% !important;
  }
  
  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 24px;
 }
 
 .vigortitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 17px;
 }

 .viglistitem{
  font-size: 17px;
}

.vigorlearnmore{
  font-size:13px;
}

}

@media only screen and (min-width:435px) and (max-width:568px){

  .vigortitlesection{
    padding-bottom: 0rem;
  }

  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 24px;
 }
 
 .vigortitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 17px;
 }

 .viglistitem{
  font-size: 17px;
}

.vigorlearnmore{
  font-size:12px;
}

}

@media only screen and (min-width:305px) and (max-width:434px){

  .vigortitlesection{
    padding-bottom: 0rem;
  }

  .vigorCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:24px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 18px;
 }
 
 .vigortitledesc2{
   font-size: 17px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 16px;
 }

 .viglistitem{
  font-size: 16px;
}

.vigorlearnmore{
  font-size:12px;
}

}

@media only screen and (max-width:304px) {

  .vigortitlesection{
    padding-bottom: 0rem;
  }

  .vigorCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vigorImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vigorcontent{
    padding-top:0;
  }

  .vigortitle{
    color: #FF3F0F;
    font-size:22px;
    font-weight:900;
 }
 
 .vigortitledesc{
   font-size: 16px;
 }
 
 .vigortitledesc2{
   font-size: 15px;
   margin-bottom: 0 !important;
 }
 
 .vigorcontentpara{
   font-size: 14px;
 }

 .viglistitem{
  font-size: 14px;
}

.vigorlearnmore{
  font-size:11px;
}

}

/* Vivid Section */

@media only screen and (min-width:569px) and (max-width:988px){

  .vividtitlesection{
    padding-bottom: 0rem;
  }


  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 60% !important;
  }
  
  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 24px;
 }
 
 .vividtitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 17px;
 }

 .vivlistitem{
  font-size: 17px;
}

.vividlearnmore{
  font-size:13px;
}

}

@media only screen and (min-width:435px) and (max-width:568px){

  .vividtitlesection{
    padding-bottom: 0rem;
  }

  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:28px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 24px;
 }
 
 .vividtitledesc2{
   font-size: 18px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 17px;
 }

 .vivlistitem{
  font-size: 17px;
}

.vividlearnmore{
  font-size:12px;
}

}

@media only screen and (min-width:305px) and (max-width:434px){

  .vividtitlesection{
    padding-bottom: 0rem;
  }

  .vividCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:24px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 18px;
 }
 
 .vividtitledesc2{
   font-size: 17px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 16px;
 }

 .vivlistitem{
  font-size: 16px;
}

.vividlearnmore{
  font-size:12px;
}

}

@media only screen and (max-width:304px) {

  .vividtitlesection{
    padding-bottom: 0rem;
  }

  .vividCover{
    max-width: 94% !important;
    margin-left:3% !important;
    margin-right:3% !important;
  }
  
  .vividImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }

  .vividcontent{
    padding-top:0;
  }

  .vividtitle{
    color: #FF3F0F;
    font-size:22px;
    font-weight:900;
 }
 
 .vividtitledesc{
   font-size: 16px;
 }
 
 .vividtitledesc2{
   font-size: 15px;
   margin-bottom: 0 !important;
 }
 
 .vividcontentpara{
   font-size: 14px;
 }

 .vivlistitem{
  font-size: 14px;
}

.vividlearnmore{
  font-size:11px;
}

}

/* Industrial Section */

@media only screen and (min-width:987px) and (max-width:1100px){

  .industrialCover{
    max-width:90% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:32px;
  }
  
  .bmstitledesc{
    font-size: 20px;
  }
  
  .industrialtitle{
    font-size: 26px;
  }
  
  .industrialtitledesc{
    font-size: 20px;
  }
  
  .industrialcontentpara{
     font-size: 17px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .indlistitem{
    font-size: 17px;
  }
  
}

@media only screen and (min-width:507px) and (max-width:986px){

  .industrialCover{
    max-width:90% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:30px;
  }
  
  .bmstitledesc{
    font-size: 20px;
  }
  
  .industrialtitle{
    font-size: 24px;
  }
  
  .industrialtitledesc{
    font-size: 20px;
  }
  
  .industrialcontentpara{
     font-size: 17px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    left:17%;
  }
  
  .indlistitem{
    font-size: 17px;
  }

  .IndustrialBmsImg{
    display: block !important;
    width: 60% !important;
  }

  
}

@media only screen and (min-width:431px) and (max-width:506px){


  .industrialCover{
    max-width:90% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:26px;
  }
  
  .bmstitledesc{
    font-size: 18px;
  }

  
  .industrialtitle{
    font-size: 20px;
  }
  
  .industrialtitledesc{
    font-size: 18px;
  }
  
  .industrialcontentpara{
     font-size: 16px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    position: relative;
    left:35%;
  }
  
  .indlistitem{
    font-size: 16px;
  }

  .IndustrialBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  
}

@media only screen and (min-width:301px) and (max-width:430px){
  .industrialCover{
    max-width:96% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:22px;
  }
  
  .bmstitledesc{
    font-size: 17px;
  }

  
  .industrialtitle{
    font-size: 18px;
  }
  
  .industrialtitledesc{
    font-size: 16px;
  }
  
  .industrialcontentpara{
     font-size: 15px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    position: relative;
    left:35%;
  }
  
  .indlistitem{
    font-size: 15px;
  }

  .IndustrialBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

}

@media only screen and (max-width:300px){
  .industrialCover{
    max-width:96% !important;
    margin:auto !important;
  }

  .bmstitle{
    font-size:20px;
  }
  
  .bmstitledesc{
    font-size: 16px;
  }

  
  .industrialtitle{
    font-size: 17px;
  }
  
  .industrialtitledesc{
    font-size: 15px;
  }
  
  .industrialcontentpara{
     font-size: 14px;
  }
  
  .industriallearnmore{
    font-size: 12px;
    position: relative;
    left:28%;
  }
  
  .indlistitem{
    font-size: 14px;
  }

  .IndustrialBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
  }
}

/* Automotive Section */

@media only screen and (min-width:987px) and (max-width:1100px){

  .automotiveCover{
    max-width:90% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 26px;
  }
  
  .automotivetitledesc{
    font-size: 20px;
  }
  
  .automotivecontentpara{
     font-size: 17px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 17px;
  }
  
}

@media only screen and (min-width:507px) and (max-width:986px){

  .automotiveCover{
    max-width:90% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 24px;
  }
  
  .automotivetitledesc{
    font-size: 20px;
  }
  
  .automotivecontentpara{
     font-size: 17px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 17px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    width: 60% !important;
  }
  
}

@media only screen and (min-width:431px) and (max-width:506px){

  .automotiveCover{
    max-width:90% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 22px;
  }
  
  .automotivetitledesc{
    font-size: 18px;
  }
  
  .automotivecontentpara{
     font-size: 16px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 16px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }
  
}

@media only screen and (min-width:301px) and (max-width:430px){
  .automotiveCover{
    max-width:96% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 20px;
  }
  
  .automotivetitledesc{
    font-size: 17px;
  }
  
  .automotivecontentpara{
     font-size: 15px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 15px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

}

@media only screen and (max-width:300px){
  .automotiveCover{
    max-width:96% !important;
    margin:auto !important;
  }


  .automotivetitle{
    font-size: 17px;
  }
  
  .automotivetitledesc{
    font-size: 15px;
  }
  
  .automotivecontentpara{
     font-size: 14px;
  }
  
  .automotivelearnmore{
    font-size: 12px;
    left:34%;
  }
  
  .autlistitem{
    font-size: 14px;
  }

  .AutomotiveBmsImg{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

}
/* About page title Section */

.aboutcoverimg {
  width: 100vw;
  height: 70vh;
}

.abouttitle {
  font-size: 45px;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 42%;
  font-weight: 900;
  text-shadow: 2px 2px 4px #ffffff;
} 

.abouttitleText {
  position: absolute;
  text-align: center;
  max-width: 60%;
  top: 42%;
  margin-left: 20%;
  margin-right: 20%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}


/* Section 1 */

.cover{
   max-width: 80%;
   margin:auto;
}

.visionimg,.missionimg{
    box-shadow: -20px -20px 0px 1px rgba(191,218,213,1);
    border-radius: 2%;
}

.visiontitle,.missiontitle{
    font-size: 34px;
    font-weight: 900;
}
.visiontext,.missiontext{
    font-size: 16px;
    font-weight: 600;
}

.visiontext{
max-width: 90%;
line-height: 1.5;
}

.missiontext{
    line-height: 1.5;
}

.aboutruler{
   color:#FF3F0F;
   width : 80%;
   margin-left:10%;
}

/* Section 2 */

.corevaluetitle{
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 0;
}

.corevaluetext{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    word-spacing: 1.5;
    max-width: 42%;
    margin:auto
}

.list{
    list-style-type: circle;
}

.title{
    color: #FF3F0F;
    font-size: 22px;
    font-weight: bold;
}

.Points{
    font-size: 18px;
    font-weight: 600;
}

.corevaluecontain{
    max-width:75%;
    margin:auto;
}

/* Media Query */

/* About page title Section */

@media only screen and (min-width:626px) and (max-width:927px){
    .abouttitle {
        font-size: 36px;
        top: 25%;
      } 
      
      .abouttitleText {
        max-width: 70%;
        top: 36%;
        margin-left: 15%;
        margin-right: 15%;
        font-size: 16px;
      }
      
      
}

@media only screen and (max-width:625px){
    .abouttitle {
        font-size: 30px;
        top: 24%;
        left: 39%;
      } 
      
      .abouttitleText {
        max-width: 80%;
        top: 32%;
        margin-left: 10%;
        margin-right: 10%;
        font-size: 15px;
      } 

      .titlecover{
          margin-bottom: 12px !important;
      }
}

/* Section 1 */

@media only screen and (min-width:509px) and (max-width:826px){

    .visiontitle,.missiontitle{
        font-size: 30px;
    }
    .visiontext,.missiontext{
        font-size: 16px;
    }
    
    .visiontext{
    max-width: 100%;
    }
    

}

@media only screen and (max-width:508px){


    .cover{
        max-width: 90%;
    }
    .visiontitle,.missiontitle{
        font-size: 28px;
    }
    .visiontext,.missiontext{
        font-size: 16px;
    }
    
    .visiontext{
    max-width: 100%;
    }
    
    .visioncol{
        padding:10px !important;
    }

    .missioncol{
        padding:12px !important;
    }

}

/* Section 2 */

@media only screen and (min-width:904px) and (max-width:1125px){

    .corevaluetitle{
        font-size: 28px;
    }
    
    .corevaluetext{
        font-size: 18px;
        max-width: 50%;
    }
    
    .title{
        font-size: 20px;
    }
    
    .Points{
        font-size: 17px;
    }
    
    .corevaluecontain{
        max-width:80%;
    }

}

@media only screen and (min-width:740px) and (max-width:903px){

    .corevaluetitle{
        font-size: 28px;
    }
    
    .corevaluetext{
        font-size: 18px;
        max-width: 60%;
    }
    
    .title{
        font-size: 20px;
    }
    
    .Points{
        font-size: 17px;
    }
    
    .corevaluecontain{
        max-width:90%;
    }
}

@media only screen and (min-width:572px) and (max-width:740px){
 

    .corevaluetitle{
        font-size: 28px;
    }
    
    .corevaluetext{
        font-size: 16px;
        max-width: 60%;
    }
    
    .title{
        font-size: 18px;
    }
    
    .Points{
        font-size: 16px;
    }
    
    .corevaluecontain{
        max-width:95%;
    }

}

@media only screen and (min-width:465px) and (max-width:571px){

    .corevaluetitle{
        font-size: 26px;
    }
    
    .corevaluetext{
        font-size: 15px;
        max-width: 80%;
    }
    
    .title{
        font-size: 17px;
    }
    
    .Points{
        font-size: 15px;
    }
    
    .corevaluecontain{
        max-width:100%;
    }

    .Img{
        margin-top: 2.5rem !important;
        margin-left: 0.5rem !important;
    }
}

@media only screen and (min-width:361px) and (max-width:464px){

    .corevaluetitle{
        font-size: 26px;
    }
    
    .corevaluetext{
        font-size: 15px;
        max-width: 80%;
    }
    
    .title{
        font-size: 17px;
    }
    
    .Points{
        font-size: 15px;
    }
    
    .corevaluecontain{
        max-width:100%;
    }

    .Img{
        margin-top: 3.5rem !important;
        margin-left: 0.5rem !important;
    }

    .aboutimgcol{
        padding: 0 5px;
    }

    .abouttextcol{
        padding-left: 2px !important;
        padding-right:8px !important;
    }

}

@media only screen and (max-width:360px){

    .corevaluetitle{
        font-size: 26px;
    }
    
    .corevaluetext{
        font-size: 15px;
        max-width: 80%;
    }
    
    .title{
        font-size: 17px;
    }
    
    .Points{
        font-size: 15px;
    }
    
    .corevaluecontain{
        max-width:100%;
    }

    .Img{
        margin-top: 3.5rem !important;
        margin-left: 0.5rem !important;
    }

    .aboutimgcol{
        padding: 0;
    }

    .abouttextcol{
        padding-left: 2px !important;
        padding-right:8px !important;
    }

}
.model {
    font-family: 'Gowun Dodum', sans-serif;
}

.modal-confirm {
    color: #636363;
    width: 325px;
    margin: auto;
    font-size: 14px;
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    margin: auto;
}

.modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
    margin-top: 15vh;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}

.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}

.modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #FF3F0F;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #FF3F0F;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: linear-gradient(90deg, #f87116, #FF3F0F);
    outline: none;
    box-shadow: none;
}


.modal-title {
    padding: 2% 0 2% 0;
    font-size: xx-large !important;
}

.modal-body {
    padding: 5% 0 2% 0;
}

.text {
    padding: 1% 0 1% 0;
    font-size: medium;
    text-align: center;
    line-height: 1.5;
    word-spacing: 0.2rem;
}

@media only screen and (max-width: 325px) {
    .modal-confirm .modal-header {
        margin-top: 12vh;
    }

    .modal-confirm .icon-box i {
        font-size: 48px;
        top: 8px;
    }

    .modal-confirm .icon-box {
        width: 90px;
        height: 90px;
    }

    .modal-confirm {
        width: 90% !important;
    }

    .modal-title {
        font-size: x-large !important;
    }

    .text {
        font-size: 15px;
    }

}
.marketcoverimg {
    width: 100vw;
    height: 80vh;
  }
  
  .marketstitle {
    font-size: 45px;
    color:#000;
    position: absolute;
    top: 40%;
    left: 18%;
    font-weight: 900;
    text-shadow: 2px 2px 4px #ffffff;
  } 

  .cover{
    max-width: 80%;
    margin:auto;
 }

 .levtitle,.ivtitle,.estitle{
  font-size: 34px;
  font-weight: 900;
}
.levtext,.ivtext,.estext{
  font-size: 16px;
  font-weight: 600;
}

.levtext,.ivtext,.estext{
line-height: 1.5;
}


.aboutruler{
 color:#FF3F0F;
 width : 80%;
 margin-left:10%;
}
  

@media only screen and (min-width:626px) and (max-width:927px){
  .marketstitle {
      font-size: 36px;
    }   
}

@media only screen and (max-width:625px){
  .marketstitle {
      font-size: 30px;
    } 
    .markettitlecover{
        margin-bottom: 12px !important;
    }
}

/* Section 1 */

@media only screen and (min-width:509px) and (max-width:826px){

  .levtitle,.ivtitle,.estitle{
      font-size: 30px;
  }
  .levtext,.ivtext,.estext{
      font-size: 16px;
  }
  
  .levtext,.estext{
  max-width: 100%;
  }
  

}

@media only screen and (max-width:508px){
    .marketcoverimg {
        width: 100vw;
        height: 50vh;
      }

        
  .marketstitle {
    font-size: 45px;
    color:#000;
    position: absolute;
    top: 35%;
    left: 18%;
    font-weight: 900;
    text-shadow: 2px 2px 4px #ffffff;
  } 

  .cover{
      max-width: 90%;
  }
  .levtitle,.ivtitle,.estitle{
      font-size: 28px;
  }
  .levtext,.ivtext,.estext{
      font-size: 16px;
  }
  
  .levtext,.estext{
      max-width: 100%;
  }
  
  .levcol,.escol{
      padding:10px !important;
  }

  .ivcol{
      padding:12px !important;
  }

}

.imgundercons{
  margin : auto;
  display: block;
  margin-top: 7%;
}
.Navbarbigscreen{
    padding : 5px 5px 5px 25px;
}

.navstyle{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(54, 54, 54);;
    text-decoration: none!important;
  }

  


  .navstyle:hover{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(247, 151, 8);;
    text-decoration:none;
    -webkit-text-decoration-color: rgb(228, 93, 3);
            text-decoration-color: rgb(228, 93, 3);
    -webkit-transform: 'right 1s';
            transform: 'right 1s';
  }

  .active_nav{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(247, 84, 8);;
    text-decoration: none!important;   
  }

  
  .active_nav:hover{
    display: block;
    padding: 1.2rem 0rem 1rem 0rem;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(247, 84, 8);;
    text-decoration: none!important;
   
   
  }



  .nav_button{
    display: block;
    padding: 0.9rem 0rem 0.5rem 0rem;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
    color: hsl(32, 82%, 53%);;
    text-decoration: none!important;
  }


  .navuser{
    display: block;
    padding: 0.8rem 0rem 0rem 0rem;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none!important;
  }

  .user{
    border-radius: 50%;
    margin-top:0px;
    margin-left: 5px;
    width: 28px;
  }

 

  .navlogo{
    width: 130px;
    display: block;
    padding: 0.5rem 0rem 0.5rem 0rem;
    text-transform: uppercase;
    text-decoration: none!important;
  }

 
@media only screen and (max-width: 1100px) {

    .navuser{
        display: block;
        padding: 0.2rem 0rem 0.1rem 0rem;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none!important;
      }

      
    .navlogo{
        width: 100px;
        display: block;
        padding: 0rem 0rem 0rem 0rem;
        text-transform: uppercase;
        text-decoration: none!important;
      }

    

      .ham{
        display: block;
        padding: 0.3rem 0rem 0.2rem 0rem;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 800;
        text-decoration: none!important;
      }
}




  
.lin{
    list-style-type:none;
    color: #FF3F0F;
    text-align: center; 
    background-color: #ffffff;
    padding : 3px!important;

}

.lin li a{
    color: #000000;
    font-weight: 900;
}

